import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'Assets/styles/_core.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import {
  NODE_ENV,
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,
  VERSION,
} from './Utils/constants';

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    sampleRate: SENTRY_SAMPLE_RATE,
    environment: NODE_ENV,
    integrations: [new BrowserTracing()],
    release: VERSION,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
