/* eslint-disable */
import { dataLayerPlanNames, PlanChangePayload, PlanTypes } from 'Interfaces/Billing';
import { User } from 'Interfaces/User';
import { formatDateToHumanString } from 'Utils/formatters';

declare var dataLayer;

interface ECOMMERCE_ITEM {
  item_id?: string;
  item_name?: string;
  currency?: string;
  discount?: number;
  item_category?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  price?: string;
  quantity?: number;
}
class DataLayerAnalytics {
  firePlanChangeEvent = (plan: PlanChangePayload) => {
    // const planInfo: string = plan.type === PlanTypes.FREE ? 'Select Free Plan' : dataLayerPlanNames[plan.type][plan.duration]?.title;
    //
    // dataLayer.push({
    //   event: 'subscription',
    //   PricingPlanName: planInfo,
    // });
  };

  fireUnconfirmedRegistrationEvent = (userId: string) => {
    dataLayer.push({
      event:'unconfirmedstatus',
      CreateFreeAccount: 'Free Account Registration',
      userId,
    });
  };

  fireAppSumoRegistrationEvent = () => {
    dataLayer.push({
      event: 'appsumoregistration',
      RegistrationAppSumo: 'AppSumoReg',
    });
  };

  fireConfirmedRegistrationEvent = (userId: string) => {
    dataLayer.push({
      event:'confirmedstatus',
      ConfirmedAccount: 'Confirmed Account',
      userId,
    });
  };

  fireGoogleRegistrationEvent = () => {
    dataLayer.push({
      event:'confirmedstatus',
      ConfirmedAccount: 'Confirmed Account (SIGN UP with GOOGLE)'
    });
  };

  fireUserIdEvent = (user: User) => {
    dataLayer.push({
      event:'userId',
      userId: user.id,
      registrationDate: user.createdAt && formatDateToHumanString(user.createdAt.toString(), true),
      userType: user.plan.type,
      billingPeriod: user.plan.duration,
      appSumo: !!(user.appSumoStatus),
      teamId: user.teamId,
      role: user.role,
    });
  };

  fireSubscriptionEvent = (planName: string, transactionId: string) => {
    dataLayer.push({
      event:'subscription',
      PricingPlanName: planName,
      transactionID: transactionId,
    });
  };

  firePurchaseEvent = ({
    transaction_id,
    previous_plan_name,
    count_of_docs_saved,
    item,
  }: {
    transaction_id: string;
    previous_plan_name: string;
    count_of_docs_saved: number;
    item: ECOMMERCE_ITEM;
  }) => {
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id,
        previous_plan_name,
        count_of_docs_saved,
        items: [{ ...item, affiliation: 'Signaturely', item_brand: 'Signaturely' }],
      },
    });
  };
}

export default new DataLayerAnalytics();
