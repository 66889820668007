import { useCallback } from 'react';
import * as _ from 'lodash';
import { DocumentPart } from 'Interfaces/Document';

export default () => {
  const getPages = useCallback((files: DocumentPart[]) => {
    const orderedFiles = _.orderBy(files, 'order', 'asc');
    return orderedFiles.map(file => file.pdfFileKey) as string[];
  }, []);

  return getPages;
};
