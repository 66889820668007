import React, { useEffect, useRef, useCallback } from 'react';
import interact from 'interactjs';
import classnames from 'classnames';
import { useToggler } from 'Hooks/Common';
import OverlayLoader from 'Components/UIComponents/UIOverlayLoader';
import { Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export interface DocumentPageProps {
  pageNumber: number;
  scale?: number;
  style?: React.CSSProperties;
  className?: string;
  onTap?: (event) => void;
  onDrop?: (event) => void;
  onLoad?: () => void;
  renderAnnotationLayer?: boolean;
}

const DocumentPage = ({
  style,
  pageNumber,
  onTap,
  onDrop,
  onLoad,
  className,
  scale,
  renderAnnotationLayer = true,
}: DocumentPageProps) => {
  const [isLoading, toggleIsLoading] = useToggler(true);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let interactable;

    if (pageRef.current)
      interactable = interact(pageRef.current)
        .dropzone({
          enabled: true,
          accept: '.draggable',
          overlap: 1,
          ondrop: onDrop,
        })
        .on('tap', event => onTap && onTap(event));

    return () => {
      if (interactable) interactable.unset();
    };
  }, [pageRef, onTap, onDrop]);

  const handleLoad = useCallback(() => {
    toggleIsLoading();
    if (onLoad) onLoad();
  }, [onLoad, toggleIsLoading]);

  return (
    <div className={classnames('documentPage', className)} style={style} ref={pageRef}>
      <div className="documentPage__inner">
        {isLoading && <OverlayLoader />}
        <Page
          className="documentPage__inner-pdf_page"
          pageNumber={pageNumber}
          scale={scale}
          onLoadSuccess={handleLoad}
          renderAnnotationLayer={renderAnnotationLayer}
          renderTextLayer={false}
        />
      </div>
    </div>
  );
};

export default DocumentPage;
