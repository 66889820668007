import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  AppSumoBillingTable,
  BillingDetails,
  InvoiceTable,
  LifeTimeDealInfoBlock,
} from '../../components';
import UIButton from 'Components/UIComponents/UIButton';
import {
  useSubscriptionDataGet,
  usePlansGet,
  usePlanChange,
  useInvoicesGet,
  useBillingPortalCreate,
} from 'Hooks/Billing';
import Toast from 'Services/Toast';
import {
  selectInvoices,
  selectInvoicesPaginationData,
  selectSubscriptionInfo,
  selectUser,
  selectUserPlan,
} from 'Utils/selectors';
import { PlanTypes, PlanDurations, InvoiceTypes } from 'Interfaces/Billing';
import ConfirmModal from 'Components/ConfirmModal';
import {
  useDataOrdering,
  useModal,
  useNewTabOpen,
  usePagination,
  useUrlParamsGet,
} from 'Hooks/Common';
import { User, UserStatuses } from 'Interfaces/User';
import CardForm from 'Components/CardForm';
import SubscriptionInfoBlockMobile from '../../components/SubscriptionInfoBlockMobile';
import classNames from 'classnames';
import useIsMobile from 'Hooks/Common/useIsMobile';
import { PastDueForm } from 'Components/PastDueForm';
import { isNotEmpty } from 'Utils/functions';
import History from 'Services/History';
import { AuthorizedRoutePaths } from 'Interfaces/RoutePaths';
import { OrderingDirection } from 'Interfaces/Common';

type selectedPage = { selected: number };

const BillingMainScreen = () => {
  const [getInvoices, isInvoicesLoading] = useInvoicesGet();
  const userPlan = useSelector(selectUserPlan);
  const { appSumoStatus, status, ...user }: User = useSelector(selectUser);
  const [getPlans] = usePlansGet();
  const [changePlan, isChangingPlan] = usePlanChange();
  const [getSubscriptionData, isGettingSubscriptionData] = useSubscriptionDataGet();
  const [createBillingPortal, isCreatingBillingPortal] = useBillingPortalCreate();
  const subscriptionInfo = useSelector(selectSubscriptionInfo);
  const invoices = useSelector(selectInvoices);
  const isMobile = useIsMobile();
  const [openNewTab] = useNewTabOpen();
  const urlParams = useUrlParamsGet();
  const { requestOrdering, orderingConfig } = useDataOrdering(invoices, {
    key: 'createdAt',
    direction: OrderingDirection.DESC,
  });
  const [paginationProps, setPageNumber] = usePagination({
    paginationSelector: selectInvoicesPaginationData,
    itemsLimit: 5,
  });

  useEffect(() => {
    if (urlParams.success_payment) {
      History.push(AuthorizedRoutePaths.SETTINGS_BILLING);
      Toast.success('Payment was successful');
    }
  }, [urlParams]);

  const handleSubscriptionDataGet = useCallback(async () => {
    try {
      await getSubscriptionData(undefined);
    } catch (error) {
      Toast.handleErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscriptionCancel = useCallback(async () => {
    try {
      await changePlan({
        type: PlanTypes.FREE,
        duration: PlanDurations.MONTHLY,
      });
      await handleSubscriptionDataGet();
    } catch (error) {
      Toast.handleErrors(error);
    }
  }, [changePlan, handleSubscriptionDataGet]);

  const handlePlansGet = useCallback(async () => {
    try {
      await getPlans(undefined);
    } catch (error) {
      Toast.handleErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInvoicesGet = useCallback(async () => {
    try {
      await getInvoices({
        types: user.ltdTierId
          ? [InvoiceTypes.DEFAULT, InvoiceTypes.LTD]
          : [InvoiceTypes.DEFAULT],
        page: paginationProps.pageNumber + 1,
        limit: paginationProps.itemsLimit,
        orderingKey: orderingConfig.key,
        orderingDirection: orderingConfig.direction.toUpperCase(),
      });
    } catch (error) {
      Toast.handleErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps.itemsLimit, paginationProps.pageNumber, orderingConfig]);

  const [showSubscriptionCancelModal, hideSubscriptionCancelModal] = useModal(
    () => (
      <ConfirmModal
        onClose={hideSubscriptionCancelModal}
        isCancellable
        confirmButtonProps={{
          isLoading: isChangingPlan || isGettingSubscriptionData,
          disabled: isChangingPlan || isGettingSubscriptionData,
          priority: 'primary',
          handleClick: async () => {
            await handleSubscriptionCancel();
            hideSubscriptionCancelModal();
          },
          title: 'Cancel Subscription',
        }}
        onCancel={hideSubscriptionCancelModal}
      >
        <div className="billing__plan-modal">
          <div className="billing__plan-modal-title">Cancel Subscription</div>
          <div className="billing__plan-modal-subtitle">
            Do you want to cancel subscription?
          </div>
        </div>
      </ConfirmModal>
    ),
    [handleSubscriptionCancel, isChangingPlan, isGettingSubscriptionData],
  );

  useEffect(() => {
    handlePlansGet();
    handleInvoicesGet();
    handleSubscriptionDataGet();
  }, [handleInvoicesGet, handlePlansGet, handleSubscriptionDataGet]);

  const handleOpenBillingPortal = useCallback(async () => {
    try {
      const response = await createBillingPortal(undefined);

      if (isNotEmpty(response)) {
        openNewTab(response.checkoutUrl);
      }
    } catch (err) {
      Toast.handleErrors(err);
    }
  }, [createBillingPortal, openNewTab]);

  return (
    <div className="billing">
      <div className="settings__block">
        <h1 className="settings__title">Card Details</h1>
        <div
          className={classNames('billing__card settings__block--small', {
            mobile: isMobile,
          })}
        >
          <PastDueForm />
        </div>
        <div
          className={classNames('billing__card settings__block--small', {
            mobile: isMobile,
          })}
        >
          <CardForm />
        </div>
      </div>
      {user.ltdTierId ? (
        <div className="settings__block">
          <LifeTimeDealInfoBlock ltdId={user.ltdTierId} />
        </div>
      ) : (
        <div className="settings__block">
          <SubscriptionInfoBlockMobile
            appSumoStatus={appSumoStatus}
            subscriptionInfo={subscriptionInfo}
            plan={userPlan}
            isFreezed={status === UserStatuses.FREEZE}
          />
        </div>
      )}
      {appSumoStatus && (
        <div className="settings__block">
          <AppSumoBillingTable isAlignLeftTitle />
        </div>
      )}
      <div className="settings__block">
        <div className={classNames('billing__details-section', { mobile: isMobile })}>
          <BillingDetails />
        </div>
      </div>
      <div className="settings__block">
        <InvoiceTable
          invoiceItems={invoices}
          isLoading={isInvoicesLoading}
          requestOrdering={requestOrdering}
          paginationProps={paginationProps}
          setPageNumber={setPageNumber}
        />
        <UIButton
          priority="secondary"
          className="settings__button-cancel"
          title="Open Billing Portal"
          handleClick={handleOpenBillingPortal}
          isLoading={isCreatingBillingPortal}
          disabled={isCreatingBillingPortal}
        />
      </div>
      {!appSumoStatus && (
        <div className="settings__block">
          <div className="billing__header">Cancel subscription</div>
          <div className={classNames('billing__details-description', { mobile: true })}>
            <p className="settings__text settings__text--grey">
              If you want to cancel subscription, please do it here.
            </p>
          </div>
          <UIButton
            priority="secondary"
            className="settings__button-cancel"
            title="Cancel subscription"
            handleClick={showSubscriptionCancelModal}
            isLoading={isChangingPlan}
            disabled={userPlan.type === PlanTypes.FREE}
          />
        </div>
      )}
    </div>
  );
};

export default BillingMainScreen;
