import { WORKFLOW_PREFIX } from 'Utils/constants';

const addPrefix = path => `${WORKFLOW_PREFIX}${path}`;

export const RoutePaths = {
  BASE_PATH: addPrefix(''),
  APPSUMO_THANKS: addPrefix('appsumo_thanks'),
  RESET: addPrefix('reset'),
  CONFIRM_EMAIL: addPrefix('confirm_email'),
  DOCUMENT_SIGN: addPrefix('documents/:documentId/sign'),
  EMBED_DOCUMENT: addPrefix('embedded/request/:documentId'),
  ACCEPT_INVITE: addPrefix('teams/:teamId/accept_invite'),
  REMINDERS_UNLINK: addPrefix('documents/:signerId/reminders_unlink'),
  INVOICE_DOWNLOAD: addPrefix('invoices/:invoiceId/download'),
  SIGNER_DOCUMENT_DOWNLOAD: addPrefix('documents/:documentId/:signerId/download'),
  SEPARATED_DOCUMENT_DOWNLOAD: addPrefix(
    'documents/:documentId/download-separated-document',
  ),
  DOCUMENT_ACTIVITIES_DOWNLOAD: addPrefix('documents/:documentId/download-activities'),
  DOCUMENT_DOWNLOAD: addPrefix('documents/:documentId/download'),
  ORIGINAL_DOCUMENT_DOWNLOAD: addPrefix('documents/:documentId/download-original'),
  FORM_REQUESTS_SEND: addPrefix('form-requests/:formRequestId/send'),
};

export const AuthorizedRoutePaths = {
  ...RoutePaths,
  UPSELL: addPrefix('upsell'),
  DOCUMENT_PREVIEW: addPrefix('documents/:documentId/preview'),
  SIGN: addPrefix('sign'),
  ONLY_ME: addPrefix('only-me'),
  BULK_SEND: addPrefix('bulk-send'),
  ME_AND_OTHERS: addPrefix('me-and-others'),
  ONLY_OTHERS: addPrefix('only-others'),
  DOCUMENTS: addPrefix('documents'),
  RECEIVED_DOCUMENTS: addPrefix('documents/received'),
  TRASH: addPrefix('documents/trash'),
  DOCUMENTS_STATUS: addPrefix('documents/:status?'),
  DOCUMENTS_EDIT: addPrefix('documents/:documentId/edit'),
  TEMPLATES: addPrefix('templates'),
  TEMPLATES_ACTIVE: addPrefix('templates/active'),
  TEMPLATES_CREATE: addPrefix('templates/create'),
  TEMPLATES_EDIT: addPrefix('templates/:templateId/edit'),
  TEMPLATES_STATUS: addPrefix('templates/:type/:status?'),
  FORM_REQUESTS: addPrefix('form-requests'),
  FORM_REQUESTS_CREATE: addPrefix('form-requests/create'),
  FORM_REQUESTS_STATUS: addPrefix('form-requests/:status?'),
  FORM_REQUESTS_EDIT: addPrefix('form-requests/:formRequestId/edit'),
  INTEGRATIONS: addPrefix('integrations'),
  TEAM: addPrefix('team'),
  SETTINGS: addPrefix('settings'),
  SETTINGS_COMPANY: addPrefix('settings/company'),
  SETTINGS_PROFILE: addPrefix('settings/profile'),
  SETTINGS_REQUEST_HISTORY: addPrefix('settings/api/:apiKeyId/request_history'),
  SETTINGS_API: addPrefix('settings/api'),
  SETTINGS_EDIT_SIGNATURE: addPrefix('settings/edit-signature'),
  SETTINGS_BILLING: addPrefix('settings/billing'),
  SETTINGS_BILLING_PLAN: addPrefix('settings/billing/plan'),
  ACTIVATE_TRIAL: addPrefix('activate-trial'),
  TRY_TRIAL: addPrefix('try-trial'),
  REDEEM_LTD_CODE: addPrefix('primeclub/redeem'),
  LTD_UPGRADE: addPrefix('primeclub/upgrade'),
  LTD_UPGRADE_SUCCESS: addPrefix('primeclub/upgrade-success'),
};

export const UnauthorizedRoutePaths = {
  ...RoutePaths,
  SIGN_UP: addPrefix('signup'),
  CONFIRM_ACCOUNT: addPrefix('confirm-account'),
  CHANGE_PASSWORD: addPrefix('change_password'),
  LOGIN: addPrefix('login'),
  SIGN_UP_FREE: addPrefix('signup/free*'),
  SIGN_UP_BUSINESS: addPrefix('signup/business*'),
  SIGN_UP_PERSONAL: addPrefix('signup/personal*'),
  SIGN_UP_LTD: addPrefix('signup/primeclub'),
  LIFE_TIME_DEAL: addPrefix('primeclub'),
  LIFE_TIME_DEAL_TIER: addPrefix('primeclub/tier-:tierNumber'),
  LTD_PAYMENT_SUCCESS: addPrefix('primeclub/payment-success'),
  TWO_FACTOR: addPrefix('two-factor'),
  ABOUT: addPrefix('about'),
};
