import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  useAvailableSignersGet,
  useDeclineSigningRequest,
  useDocumentSigning,
  useSignatoryOpenedSend,
  useSignerDocumentGet,
} from 'Hooks/DocumentSign';
import { useBeaconRemove, useModal } from 'Hooks/Common';
import History from 'Services/History';
import { isNotEmpty } from 'Utils/functions';
import { Document, DocumentForSigners, Signer, SignerOption } from 'Interfaces/Document';
import DocumentSignModal from 'Components/DocumentSignModal';
import UISpinner from 'Components/UIComponents/UISpinner';
import { ConfirmCodeModal, DocumentDeclineModal, DocumentSignView } from './components';
import SignerSelectModal from './components/SignerSelectModal';
import useCodeAccessSend from 'Hooks/DocumentSign/useCodeAccessSend';
import Toast from 'Services/Toast';
import { isDocumentAccessRequired } from 'Utils/typeGuards';
import * as Sentry from '@sentry/react';
import { UnauthorizedRoutePaths } from 'Interfaces/RoutePaths';
import ConfirmModal from 'Components/ConfirmModal';
import UIButton from 'Components/UIComponents/UIButton';

interface PageParams {
  documentId: Document['id'];
}

const DocumentSign = ({ location, match }: RouteChildrenProps<PageParams>) => {
  useBeaconRemove();

  const [signerDocument, setSignerDocument] = useState<DocumentForSigners | null>(null);
  const [currentSigner, setCurrentSigner] = useState<SignerOption | null>(null);
  const [signerOptions, setSignerOptions] = useState<SignerOption[]>([]);

  const [initDocumentSigning, finishDocumentSigning] = useDocumentSigning();
  const [
    getAvailableSignersOptions,
    isGettingAvailableSigners,
  ] = useAvailableSignersGet();
  const [getSignerDocument, isGettingDocument] = useSignerDocumentGet();
  const [sendCodeAccess, isCodeSending] = useCodeAccessSend();
  const [sendSignatoryOpened] = useSignatoryOpenedSend();
  const [declineSigningRequest, isDeclineLoading] = useDeclineSigningRequest();

  const { signToken, declineImmediately, documentId } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const documentId = match?.params.documentId;

    return {
      signToken: searchParams.get('signAccessToken'),
      declineImmediately: searchParams.get('declineImmediately') === 'true',
      documentId,
    };
  }, [location.search, match]);

  const navigateToRoot = useCallback(() => {
    History.replace(UnauthorizedRoutePaths.BASE_PATH);
  }, []);

  const [openSignerOptionModal, closeSignerOptionModal] = useModal(
    () => (
      <SignerSelectModal
        onClose={closeSignerOptionModal}
        onSignerSelect={setCurrentSigner}
        signerOptions={signerOptions}
      />
    ),
    [signerOptions],
  );

  const [openSuccessfulSignModal, closeSuccessfulSignModal] = useModal(
    () => (
      <DocumentSignModal documentId={documentId} onClose={closeSuccessfulSignModal} />
    ),
    [documentId, currentSigner, signerDocument],
  );

  const [openSuccessfulDeclineModal, closeSuccessfulDeclineModal] = useModal(
    () => <DocumentDeclineModal onClose={closeSuccessfulDeclineModal} />,
    [],
  );

  const [openCodeConfirmationModal, closeCodeConfirmationModal] = useModal(
    () => (
      <ConfirmCodeModal
        onClose={closeCodeConfirmationModal}
        isSending={isCodeSending}
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        sendCode={handleCodeAccessSend}
        title="Enter the access code:"
      />
    ),
    [currentSigner, documentId],
  );

  const handleCodeAccessSend = useCallback(
    async (codeAccess: string) => {
      try {
        if (documentId && currentSigner) {
          const document = await sendCodeAccess({
            codeAccess,
            documentId,
            signerId: currentSigner.id,
          });

          if (isNotEmpty(document)) {
            setSignerDocument(document);
            sendSignatoryOpened({ documentId, signerId: currentSigner.id });
            closeCodeConfirmationModal();
          }
        }
      } catch (err) {
        Toast.handleErrors(err);
      }
    },
    [
      closeCodeConfirmationModal,
      currentSigner,
      documentId,
      sendCodeAccess,
      sendSignatoryOpened,
    ],
  );

  const handleSignersGet = useCallback(
    async (documentId: Document['id']) => {
      try {
        const signerOptions = await getAvailableSignersOptions({ documentId });

        if (isNotEmpty(signerOptions) && signerOptions.length === 1) {
          setCurrentSigner(signerOptions[0]);
        } else {
          setSignerOptions(signerOptions as SignerOption[]);
          openSignerOptionModal();
        }
      } catch (error) {
        Toast.handleErrors(error);
        Sentry.captureException(error, {
          extra: {
            page: 'DocumentSign',
            func: 'handleSignersGet',
          },
        });
        navigateToRoot();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAvailableSignersOptions],
  );

  const handleDeclineSigningRequest = useCallback(async () => {
    try {
      if (documentId && currentSigner)
        await declineSigningRequest({
          documentId,
          signerId: currentSigner.id,
        });
      Toast.success('Signature request was declined.');

      if (signerDocument?.redirectionPage) {
        window.location.href = signerDocument?.redirectionPage;
      } else {
        openSuccessfulDeclineModal();
      }
    } catch (error) {
      Toast.handleErrors(error);
    }
  }, [
    currentSigner,
    declineSigningRequest,
    documentId,
    openSuccessfulDeclineModal,
    signerDocument,
  ]);

  const [showDeclineConfirmModal, hideDeclineConfirmModal] = useModal(
    () => (
      <ConfirmModal
        onClose={hideDeclineConfirmModal}
        confirmComponent={() => (
          <UIButton
            priority="red"
            handleClick={handleDeclineSigningRequest}
            className="confirmModal__button--delete"
            title={'Decline'}
            isLoading={isDeclineLoading}
          />
        )}
        cancelComponent={() => (
          <div
            className="documents__deleteModal--cancel"
            onClick={hideDeclineConfirmModal}
          >
            Cancel
          </div>
        )}
        confirmText={'Decline'}
      >
        <div className="documents__deleteHeader">
          <h5 className="documents__deleteTitle">
            Are you sure want to decline this signing request?
          </h5>
        </div>
      </ConfirmModal>
    ),
    [handleSignersGet],
  );

  const handleDocumentGet = useCallback(
    async (documentId: Document['id'], signerId: Signer['id']) => {
      try {
        const document = await getSignerDocument({
          documentId,
          signerId,
        });

        if (!isNotEmpty(document)) return;

        if (isDocumentAccessRequired(document)) {
          openCodeConfirmationModal();
        } else {
          setSignerDocument(document);
          sendSignatoryOpened({ documentId, signerId });
        }

        if (declineImmediately) {
          showDeclineConfirmModal();
        }
      } catch (error) {
        Toast.handleErrors(error);
        Sentry.captureException(error, {
          extra: {
            page: 'DocumentSign',
            func: 'handleDocumentGet',
          },
        });
        navigateToRoot();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSignerDocument, sendSignatoryOpened],
  );

  useEffect(() => {
    if (!signToken || !documentId) {
      if (!signToken) {
        Toast.handleErrors({ message: 'signToken is not provided' });
      }

      if (!documentId) {
        Toast.handleErrors({ message: 'documentId is not provided' });
      }

      Sentry.captureException(new Error('Redirecting to root'), {
        extra: {
          page: 'DocumentSign',
          func: 'useEffect',
          documentId,
          signToken,
        },
      });
      return navigateToRoot();
    }

    initDocumentSigning({ token: signToken });
    handleSignersGet(documentId);

    return () => finishDocumentSigning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishDocumentSigning, initDocumentSigning, navigateToRoot, signToken]);

  useEffect(() => {
    if (currentSigner && documentId) {
      handleDocumentGet(documentId, currentSigner.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, currentSigner]);

  if (isGettingDocument || isGettingAvailableSigners) {
    return <UISpinner wrapperClassName="spinner--main__wrapper" width={50} height={50} />;
  }

  if (signerDocument) {
    return (
      <DocumentSignView
        document={signerDocument}
        openSuccessModal={openSuccessfulSignModal}
        redirectionPage={signerDocument?.redirectionPage}
      />
    );
  }

  return null;
};

export default DocumentSign;
