import React, { useCallback, useEffect, useRef, useState } from 'react';
import Toast from 'Services/Toast';
import { SignUpData } from 'Interfaces/Auth';
import { OnSubmitReturnType } from 'Interfaces/FinalForm';
import { useGoogleLoginRenderButton } from 'Hooks/Auth';
import jwt_decode from 'jwt-decode';
declare let google: any;

interface GoogleLoginFormProps {
  onSubmit: (values: SignUpData) => OnSubmitReturnType;
  width: number;
  text?: string;
}

const GoogleLoginForm = ({ onSubmit, width, text }: GoogleLoginFormProps) => {
  const [initializationSuccess, setInitializationSuccess] = useState(true);

  const handleSuccess = useCallback(
    response => {
      const parseToken: any = jwt_decode(response.credential);
      onSubmit({
        email: parseToken.email,
        id_token: response.credential,
        name: parseToken.name,
      });
    },
    [onSubmit],
  );

  const gisRenderButton = useGoogleLoginRenderButton({ onSubmit: handleSuccess });

  const handleFailure = useCallback(
    response => {
      switch (response.error) {
        case 'access_denied':
          Toast.error('Access denied');
          break;
        case 'popup_closed_by_user':
          Toast.error('You need to select your Google account in the pop-up window.');
          break;
        case 'idpiframe_initialization_failed':
          if (response.details === 'Cookies are not enabled in current environment.') {
            setInitializationSuccess(false);
          }
          break;
        default:
          Toast.error();
      }
    },
    [setInitializationSuccess],
  );

  useEffect(() => {
    gisRenderButton(document.getElementById('buttonDiv') as HTMLButtonElement, {
      type: 'standart',
      theme: 'outline',
      size: 'large',
      shape: 'pill',
      width: width,
      text: text,
    });
  }, [gisRenderButton, text, width]);

  return <div id="buttonDiv" data-context="signin" data-auto_select="true"></div>;
};

export default GoogleLoginForm;
