import sagas from './sagas';
import {
  createDocument,
  getDocument,
  getFormRequests,
  uploadDocument,
  updateDocument,
  mergeTemplate,
  $mergeTemplate,
  replicateTemplate,
  $replicateTemplate,
  deleteDocuments,
  watchDocumentConvertionProgress,
  sendReminder,
  sendDocumentBulk,
  $sendDocumentBulk,
  cleanFileData,
  copyDocument,
  $cleanFileData,
  $sendReminder,
  $createDocument,
  $getDocument,
  $copyDocument,
  getAllDocuments,
  $getAllDocuments,
  $getFormRequests,
  $uploadDocument,
  $updateDocument,
  $deleteDocuments,
  revertDocument,
  $revertDocument,
  getDocumentDownloadUrl,
  $getDocumentDownloadUrl,
  getDocumentActivitiesDownloadUrl,
  $getDocumentActivitiesDownloadUrl,
  activateTemplate,
  $activateTemplate,
  getDocumentActivities,
  $getDocumentActivities,
  createDocumentFromFormRequest,
  $createDocumentFromFormRequest,
  createDocumentByExistTemplate,
  $createDocumentByExistTemplate,
  updateDocumentByExistTemplate,
  $updateDocumentByExistTemplate,
  removeTemplateFromApi,
  $removeTemplateFromApi,
  addTemplateToApi,
  $addTemplateToApi,
  getFormRequest,
  $getFormRequest,
  disableForm,
  $disableForm,
  enableForm,
  $enableForm,
  $toggleEmailNotification,
  toggleEmailNotification,
  $getAllTemplates,
  getAllTemplates,
  $signSeparateDocument,
  signSeparateDocument,
  $getSeparateDocumentDownloadUrl,
  getSeparateDocumentDownloadUrl,
  $signSeparateDocumentActivities,
  signSeparateDocumentActivities,
  $deleteDocument,
  deleteDocument,
  $getEmbedDocument,
  getEmbedDocument,
  updateEmbedDocument,
  $updateEmbedDocument,
  initEmbedDocumentToken,
  removeEmbedDocumenToken,
  getDocumentByHash,
  $getDocumentByHash,
  getReportByEmail,
  $getReportByEmail,
} from './actionCreators';
import reducer from './reducer';

export default {
  sagas,
  reducer,
  actions: {
    createDocument,
    getDocument,
    sendReminder,
    getDocuments: getFormRequests,
    uploadDocument,
    updateDocument,
    getAllDocuments,
    mergeTemplate,
    replicateTemplate,
    revertDocument,
    deleteDocuments,
    copyDocument,
    cleanFileData,
    getDocumentDownloadUrl,
    getDocumentActivitiesDownloadUrl,
    activateTemplate,
    sendDocumentBulk,
    watchDocumentConvertionProgress,
    getDocumentActivities,
    createDocumentFromFormRequest,
    createDocumentByExistTemplate,
    updateDocumentByExistTemplate,
    removeTemplateFromApi,
    addTemplateToApi,
    getFormRequest,
    disableForm,
    enableForm,
    toggleEmailNotification,
    getAllTemplates,
    signSeparateDocument,
    getSeparateDocumentDownloadUrl,
    signSeparateDocumentActivities,
    deleteDocument,
    getDocumentByHash,
    getEmbedDocument,
    updateEmbedDocument,
    initEmbedDocumentToken,
    removeEmbedDocumenToken,
    getReportByEmail,
  },
};

export const $actions = {
  createDocument: $createDocument,
  getDocument: $getDocument,
  getDocumentByHash: $getDocumentByHash,
  getFormRequests: $getFormRequests,
  revertDocument: $revertDocument,
  mergeTemplate: $mergeTemplate,
  replicateTemplate: $replicateTemplate,
  sendReminder: $sendReminder,
  uploadDocument: $uploadDocument,
  updateDocument: $updateDocument,
  toggleEmailNotification: $toggleEmailNotification,
  sendDocumentBulk: $sendDocumentBulk,
  getAllDocuments: $getAllDocuments,
  copyDocument: $copyDocument,
  deleteDocuments: $deleteDocuments,
  cleanFileData: $cleanFileData,
  getDocumentDownloadUrl: $getDocumentDownloadUrl,
  getDocumentActivitiesDownloadUrl: $getDocumentActivitiesDownloadUrl,
  activateTemplate: $activateTemplate,
  getDocumentActivities: $getDocumentActivities,
  createDocumentFromFormRequest: $createDocumentFromFormRequest,
  createDocumentByExistTemplate: $createDocumentByExistTemplate,
  updateDocumentByExistTemplate: $updateDocumentByExistTemplate,
  addTemplateToApi: $addTemplateToApi,
  removeTemplateFromApi: $removeTemplateFromApi,
  getFormRequest: $getFormRequest,
  disableForm: $disableForm,
  enableForm: $enableForm,
  getAllTemplates: $getAllTemplates,
  signSeparateDocument: $signSeparateDocument,
  getSeparateDocumentDownloadUrl: $getSeparateDocumentDownloadUrl,
  signSeparateDocumentActivities: $signSeparateDocumentActivities,
  deleteDocument: $deleteDocument,
  getEmbedDocument: $getEmbedDocument,
  updateEmbedDocument: $updateEmbedDocument,
  getReportByEmail: $getReportByEmail,
};
