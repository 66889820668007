import {
  initDocumentSigning,
  finishDocumentSigning,
  getAvailableSignersOptions,
  $getAvailableSignersOptions,
  getSignerDocument,
  $getSignerDocument,
  submitSignedDocument,
  $submitSignedDocument,
  declineSigningRequest,
  $declineSigningRequest,
  sendDocumentOut,
  $sendDocumentOut,
  sendReminders,
  $sendReminders,
  sendSignatoryOpened,
  $sendSignatoryOpened,
  getDocumentPreviewPages,
  $getDocumentPreviewPages,
  getDocumentShareUrl,
  $getDocumentShareUrl,
  shareDocument,
  $shareDocument,
  getDocumentConvertionStatus,
  $getDocumentConvertionStatus,
  $sendCodeAccess,
  $getSigningUrl,
  sendCodeAccess,
  getSigningUrl,
  unlinkSigningReminders,
  $unlinkSigningReminders,
  sendOutEmbedDocument,
  $sendOutEmbedDocument,
  getEmbedDocumentPreviewPages,
  $getEmbedDocumentPreviewPages,
} from './actionCreators';
import sagas from './sagas';

export default {
  sagas,
  actions: {
    initDocumentSigning,
    finishDocumentSigning,
    getSignerDocument,
    submitSignedDocument,
    declineSigningRequest,
    getAvailableSignersOptions,
    sendDocumentOut,
    sendReminders,
    sendSignatoryOpened,
    getDocumentPreviewPages,
    getDocumentShareUrl,
    shareDocument,
    getDocumentConvertionStatus,
    sendCodeAccess,
    getSigningUrl,
    unlinkSigningReminders,
    sendOutEmbedDocument,
    getEmbedDocumentPreviewPages,
  },
};

export const $actions = {
  getSignerDocument: $getSignerDocument,
  submitSignedDocument: $submitSignedDocument,
  declineSigningRequest: $declineSigningRequest,
  getAvailableSignersOptions: $getAvailableSignersOptions,
  sendDocumentOut: $sendDocumentOut,
  sendReminders: $sendReminders,
  sendSignatoryOpened: $sendSignatoryOpened,
  getDocumentPreviewPages: $getDocumentPreviewPages,
  getDocumentShareUrl: $getDocumentShareUrl,
  shareDocument: $shareDocument,
  getDocumentConvertionStatus: $getDocumentConvertionStatus,
  sendCodeAccess: $sendCodeAccess,
  getSigningUrl: $getSigningUrl,
  unlinkSigningReminders: $unlinkSigningReminders,
  sendOutEmbedDocument: $sendOutEmbedDocument,
  getEmbedDocumentPreviewPages: $getEmbedDocumentPreviewPages,
};
