import sagas from './sagas';
import {
  getCard,
  $getCard,
  updateCard,
  attachCard,
  $attachCard,
  getSubscriptionData,
  $getSubscriptionData,
  getApiSubscription,
  $getApiSubscription,
  getPlans,
  $getPlans,
  removeApiPlan,
  $removeApiPlan,
  changePLan,
  $changePLan,
  changeApiPlan,
  $changeApiPlan,
  getInvoices,
  upgradeAppSumo,
  $upgradeAppSumo,
  $getInvoices,
  $updateCard,
  retryCharge,
  $retryCharge,
  upsellPlan,
  $upsellPlan,
  validatePromotionCode,
  $validatePromotionCode,
  getLatestInvoice,
  $getLatestInvoice,
  creatSetupCheckoutSession,
  $creatSetupCheckoutSession,
  createSubscriptionCheckout,
  $createSubscriptionCheckout,
  createLtdPaymentCheckout,
  $createLtdPaymentCheckout,
  upgradeLtdPaymentCheckout,
  $upgradeLtdPaymentCheckout,
  checkUpsellAllowed,
  $checkUpsellAllowed,
  createBillingPortal,
  $createBillingPortal,
  getUpcomingInvoice,
  $getUpcomingInvoice,
  getLtdUpcomingInvoice,
  $getLtdUpcomingInvoice,
  getInvoiceDownloadLink,
  $getInvoiceDownloadLink,
  changeLtdPlanDuration,
  $changeLtdPlanDuration,
  capturePaypalOrder,
  $capturePaypalOrder,
  getLtdTier,
  $getLtdTier,
  getLtdTiers,
  $getLtdTiers,
  validateLtdCode,
  $validateLtdCode,
  redeemLtdCode,
  $redeemLtdCode,
} from './actionCreators';
import reducer from './reducer';

export default {
  sagas,
  reducer,
  actions: {
    getCard,
    getApiSubscription,
    getPlans,
    upgradeAppSumo,
    attachCard,
    changePLan,
    changeApiPlan,
    getSubscriptionData,
    updateCard,
    removeApiPlan,
    getInvoices,
    retryCharge,
    upsellPlan,
    validatePromotionCode,
    getLatestInvoice,
    creatSetupCheckoutSession,
    createSubscriptionCheckout,
    createLtdPaymentCheckout,
    upgradeLtdPaymentCheckout,
    checkUpsellAllowed,
    createBillingPortal,
    getUpcomingInvoice,
    getLtdUpcomingInvoice,
    getInvoiceDownloadLink,
    changeLtdPlanDuration,
    capturePaypalOrder,
    getLtdTier,
    getLtdTiers,
    validateLtdCode,
    redeemLtdCode,
  },
};

export const $actions = {
  getInvoices: $getInvoices,
  getApiSubscription: $getApiSubscription,
  removeApiPlan: $removeApiPlan,
  getCard: $getCard,
  attachCard: $attachCard,
  upgradeAppSumo: $upgradeAppSumo,
  changeApiPlan: $changeApiPlan,
  getPlans: $getPlans,
  getSubscriptionData: $getSubscriptionData,
  updateCard: $updateCard,
  changePLan: $changePLan,
  retryCharge: $retryCharge,
  upsellPlan: $upsellPlan,
  validatePromotionCode: $validatePromotionCode,
  getLatestInvoice: $getLatestInvoice,
  creatSetupCheckoutSession: $creatSetupCheckoutSession,
  createSubscriptionCheckout: $createSubscriptionCheckout,
  createLtdPaymentCheckout: $createLtdPaymentCheckout,
  upgradeLtdPaymentCheckout: $upgradeLtdPaymentCheckout,
  checkUpsellAllowed: $checkUpsellAllowed,
  createBillingPortal: $createBillingPortal,
  getUpcomingInvoice: $getUpcomingInvoice,
  getLtdUpcomingInvoice: $getLtdUpcomingInvoice,
  getInvoiceDownloadLink: $getInvoiceDownloadLink,
  changeLtdPlanDuration: $changeLtdPlanDuration,
  capturePaypalOrder: $capturePaypalOrder,
  getLtdTier: $getLtdTier,
  getLtdTiers: $getLtdTiers,
  validateLtdCode: $validateLtdCode,
  redeemLtdCode: $redeemLtdCode,
};
